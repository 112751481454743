import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/shared/seo';
import { Header } from '../components/shared/header/header';
import { Article } from '../components/article/article';
import { LastArticles } from '../components/shared/last-articles';
import { Footer } from '../components/shared/footer/footer';
import { Container } from '../components/shared/container';
import { QuoteContent } from '../components/article/article-content';
import {
  Article as IArticle,
  ArticlePageContext,
  AlternateLanguage,
} from '../types';

interface ArticleData {
  prismicArticle: {
    data: IArticle;
    first_publication_date: string;
    id: string;
    alternate_languages: AlternateLanguage[];
  };
  prismicBlog: {
    data: {
      small_title: string;
      big_title: string;
      more_articles_section_title: string;
    };
  };
}

const ArticlePage: React.FC<PageProps<ArticleData, ArticlePageContext>> = ({
  data,
}) => {
  const { prismicArticle, prismicBlog } = data;
  const { data: articleData, first_publication_date, id } = prismicArticle;
  const articeProps = {
    date: first_publication_date,
    title: articleData.title.text,
    author: {
      name: articleData.author_name,
      role: articleData.author_role,
      image: articleData.author_photo.url,
    },
    content: articleData.body.map(element => ({
      type: element.slice_type,
      content:
        element.slice_type === 'quote'
          ? ({
              author: element.primary.author,
              quote: element.primary.quote,
            } as QuoteContent)
          : element.primary.content.raw,
    })),
  };

  return (
    <Layout>
      <SEO title={articleData.title.text} />
      <Header />
      <div className="pb-64 mt-36 sm:mt-28">
        <div className="">
          <h1>
            <Container paddingRight="big">
              <span className="header-xs text-greyscale-mediumlight -mb-3 sm:-mb-4">
                {prismicBlog.data.small_title}
              </span>
            </Container>
            <div className="max-w-body m-auto ">
              <span className="header-2xl whitespace-nowrap text-ecit-green mt-0 mb-2 sm:mt-10 sm:mb-13.5">
                {prismicBlog.data.big_title}
              </span>
            </div>
          </h1>
          <Container paddingRight="big">
            <Article {...articeProps} />
          </Container>
        </div>
        <div className="relative">
          <h2>
            <Container paddingRight="big">
              <span className="header-xs text-greyscale-mediumlight mt-20 sm:mt-25  -mb-2 sm:-mb-4">
                {prismicBlog.data.small_title}
              </span>
            </Container>
            <div className=" max-w-body m-auto">
              <span className=" max-w-body m-auto header-2xl text-greyscale-light whitespace-nowrap sm:mb-6">
                {prismicBlog.data.more_articles_section_title}
              </span>
            </div>
          </h2>
          <Container>
            <LastArticles currentArticleId={id} />
          </Container>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    prismicArticle(uid: { eq: $slug }, lang: { eq: $lang }) {
      data {
        author_name
        author_photo {
          alt
          url
        }
        author_role
        body {
          ... on PrismicArticleBodyQuote {
            primary {
              author
              quote
            }
            slice_type
          }
          ... on PrismicArticleBodyContent {
            primary {
              content {
                raw
              }
            }
            slice_type
          }
        }
        title {
          text
        }
      }
      first_publication_date(formatString: "DD / MM / yyyy")
      id
      alternate_languages {
        uid
        lang
      }
    }
    prismicBlog(lang: { eq: $lang }) {
      data {
        small_title
        big_title
        more_articles_section_title
      }
    }
  }
`;

export default ArticlePage;
